import type { ReactElement } from 'react';
// @components
import { Login, AuthLayout } from 'hohm-auth';
import React from 'react';
import { useStore } from '@app/store';
import { useRouter } from 'next/router';

export const SigninPage = () => {
	const router = useRouter();
	const login = useStore((state) => state.login);

	return (
		<Login
			login={login}
			redirect={() => router.push('/main')}
			permission="isClient"
		/>
	);
};

SigninPage.getLayout = function getLayout(page: ReactElement) {
	return <AuthLayout>{page}</AuthLayout>;
};

export default SigninPage;
